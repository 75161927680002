export enum Config {
	"Acundesolduricuzero$Incasarisiplati$",
	"adminUseriSelectati$Incasarisiplati$Incasarisiplati",
	"antetLandscapeRaport$Administraresistem$Administraresistem",
	"antetRaport$Administraresistem$Administraresistem",
	"aprobatPersoana$Setariaplicatie$Achizitii",
	"Ascundesolduricu0$Incasarisiplati$Incasarisiplati",
	"BorderouLaNivelDeOra$Incasarisiplati$Incasarisiplati",
	"cantina$Stocurisiimobilizari$Stocurisiimobilizari",
	"chitantaCumulata$Setariaplicatie$Invatamant",
	"compartimentBuget$Setariaplicatie$Documente",
	"configAchizitii$Setariaplicatie$Setariaplicatie",
	"configAdmin$Administraresistem$Administraresistem",
	"configAdministrare$Administrareinstitutie$Administrareinstitutie",
	"configAdrese$Adrese$Adrese",
	"configAsisoc$Asistentasociala$Asistentasociala",
	"configBuget$Buget$Buget",
	"configBugetUserPeUnitate$Buget$Buget",
	"configCantina$Cantina$Cantina",
	"configContab$Contab$Contab",
	"configContabUserPeUnitate$Contab$Contab",
	"configDocumente$Documente$Documente",
	"configGhiseu$Incasarisiplati$Incasarisiplati",
	"configImpotax$Impotax$Impotax",
	"configImpotaxSetari$Impotax$Impotax",
	"configIncasare$Incasarisiplati$Incasarisiplati",
	"configIncasareCasierie$Incasarisiplati$Incasarisiplati",
	"configInvatamant$Invatamant$Invatamant",
	"configInventar$Stocurisiimobilizari$Stocurisiimobilizari",
	"configInvestitii$Investitii$Investitii",
	"configParametriiRaportBalanta$Contab$Contab",
	"configParametriiRaportCentralizatorBalanta$Contab$Contab",
	"configPersoane$Persoane$Persoane",
	"configRaportariFinanciare$Raportarifinanciare$Raportarifinanciare",
	"configSalarii$Salarii$Salarii",
	"configSalariiUserPeUnitate$Salarii$Salarii",
	"ControlorCFP$SetariUtilizator$Administraresistem",
	"criteriuCautareMF$Cautareavansatamijlocfix$Stocurisiimobilizari",
	"cuFiltruIesite$Stocurisiimobilizari$Stocurisiimobilizari",
	"cumulareOpSalarii$Setariaplicatie$Documente",
	"dataInceputRefacturareUtilitati$Documente$Documente",
	"dataOperareSimplificata$Incasarisiplati$Incasarisiplati",
	"DataPlata$Incasarisiplati$Incasarisiplati",
	"DataVizeiEsteDataDocumentului$SetariUtilizator$Administraresistem",
	"defaultSursaFinantare$Setariaplicatie$Achizitii",
	"DisponibilAnexa3$Rapoarte$Buget",
	"eFactura$eFactura$Documente",
	"eFacturaUrlCID$eFactura$Documente",
	"executieSimplificata$Setariaplicatie$Documente",
	"executieSimplificataAngajamenteGlobale$Setariaplicatie$Documente",
	"executieSimplificataAngajamenteGlobaleStatColaboratori$Setariaplicatie$Documente",
	"executieSimplificataAngajamenteGlobaleStatGeneric$Setariaplicatie$Documente",
	"executieSimplificataAngajamenteGlobaleStatInspectii$Setariaplicatie$Documente",
	"executieSimplificataInclusivOrd$Setariaplicatie$Documente",
	"executieSimplificataOmiteVizaOrdonatari$Setariaplicatie$Documente",
	"faraNoteContabileInvatamant$Invatamant$Invatamant",
	"favoriteRapoarte$Administraresistem$Administraresistem",
	"favorites$Administraresistem$Administraresistem",
	"filtrareProduse$Setariaplicatie$Stocurisiimobilizari",
	"GenerareAutomataSolicitare$Achizitii$Achizitii",
	"GlobalPayKey$Incasarisiplati$Incasarisiplati",
	"GlobalPayUrl$Incasarisiplati$Incasarisiplati",
	"HomePageMenuType$Administraresistem$Administraresistem",
	"HomePagePin$Administraresistem$Administraresistem",
	"Incasaresimplificata$Incasarisiplati$Incasarisiplati",
	"Inchidereconturiautomata$Contab$Contab",
	"includeCNPRaport$Setariaplicatie$Documente",
	"Includetoateoperatiunile$Incasarisiplati$Incasarisiplati",
	"inregistrareOperatiuniDirectInRegistruDeCasa$Incasarisiplati$Incasarisiplati",
	"invatamantInjumatatireTarifAlDoileaCopil$Invatamant$Invatamant",
	"invatamantProgramPrelungit$Invatamant$Invatamant",
	"invatamantRenumerotareDosareInceputDeAn$Invatamant$Invatamant",
	"isEfectuarePlatiDepasire$Buget$Buget",
	"isOperareFacturiComplet$Setariaplicatie$Documente",
	"listaAlteDocumenteSelection$Documente$Documente",
	"listaComenziSelection$Documente$Documente",
	"listaContracteSelection$Documente$Documente",
	"listaDocumenteClientiSelection$Documente$Documente",
	"listaDocumenteClientiSoldSelection$Documente$Documente",
	"listaDocumentecuSoldSelection$Documentefurnizoricusoldinitial$Documente",
	"listaDocumenteSelection$Documente$Documente",
	"listaIesiriSelection$Bonurideconsumiesire$Stocurisiimobilizari",
	"listaOperatiuniPropriiSelection$Documente$Documente",
	"listaOperatiuniPropriiSelection$Operatiuniproprii$Nomenclatoaregenerale",
	"listaReceptiiSelection$Notedereceptie$Stocurisiimobilizari",
	"locFolosintaMf$Stocurisiimobilizari$Stocurisiimobilizari",
	"locFolosintaMfReevaluare$Reevaluare$Stocurisiimobilizari",
	"modGenerareObligatiiDePlata$Invatamant$Invatamant",
	"nuAreConta$Setariaplicatie$Documente",
	"numarExemplareListareChitante$Incasarisiplati$Incasarisiplati",
	"omitereCodIndicatorAngajamentTitlul10$Setariaplicatie$Documente",
	"OmiteVizaCFPLaExecutieBugetara$Documente$Documente",
	"operareDocumenteLaData$Incasarisiplati$Incasarisiplati",
	"Parametrirapoarte$Administraresistem$Administraresistem",
	"persoanaCurenta$Persoane$Persoane",
	"PersoanaTrimitereAutomataSolicitare$Achizitii$Achizitii",
	"predareBaniCasierieCentrala$Incasarisiplati$Incasarisiplati",
	"PreluareSemnaturiUltimaPlanificare$Cantina$Cantina",
	"prevederiDetalieriInfluenteTrimestriale$Buget$Buget",
	"prevederiFaraRanduriZero$Buget$Buget",
	"Produsecudisponibil$Stocurisiimobilizari$Stocurisiimobilizari",
	"programAnualTip$Setariaplicatie$Achizitii",
	"raportIncasare$Incasarisiplati$Incasarisiplati",
	"RecentPages$Administraresistem$Administraresistem",
	"regularizareCodUnic$Incasarisiplati$Incasarisiplati",
	"setareTipDocumentIncasariPrinBanca$Incasarisiplati$Incasarisiplati",
	"sigmaBackupPath$Backup$Administraresistem",
	"theme$Administraresistem$Administraresistem",
	"tipDocumentPredareNumerar$Incasarisiplati$Incasarisiplati",
	"TipRaport$Administraresistem$Administraresistem",
	"tipVenitFiltru$Administrareinstitutie$Administrareinstitutie",
	"trezorerieOperativa$Persoane$Persoane",
	"ultimaGrupaSelectata$Invatamant$Invatamant",
	"Validarenotecontabilepropuse$Actualizarebalanta$Contab",
	"VizaCFPAutomat$SetariUtilizator$Administraresistem",
	"vizatPersoana$Achizitii$Achizitii"
}
export enum App {
	"impotax" = 1,
	"contr" = 47,
	"buget" = 73,
	"asisoc" = 77,
	"salarii" = 80,
	"raportarifinanciare" = 84,
	"adrese" = 88,
	"persoane" = 89,
	"agroregis" = 93,
	"admin" = 95,
	"achizitii" = 776,
	"contab" = 819,
	"documente" = 1058,
	"inventare" = 1403,
	"deploy" = 1869,
	"incasari" = 2552,
	"clienti" = 2573,
	"resum" = 2738,
	"administrareinstitutie" = 2739,
	"nomenclatoaregenerale" = 2749,
	"conversiidate" = 2901,
	"invatamant" = 3245,
	"cantina" = 3557,
	"investitii" = 4162,
	"dev" = 5507,
	"user" = 5508
}
export enum AppContext {
	"favorites$Administraresistem$Administraresistem" = 22,
	"configBuget$Buget$Buget" = 30,
	"configContab$Contab$Contab" = 31,
	"Parametrirapoarte$Administraresistem$Administraresistem" = 37,
	"favoriteRapoarte$Administraresistem$Administraresistem" = 38,
	"configInventar$Stocurisiimobilizari$Stocurisiimobilizari" = 51,
	"configPersoane$Persoane$Persoane" = 53,
	"RecentPages$Administraresistem$Administraresistem" = 54,
	"configSalarii$Salarii$Salarii" = 55,
	"configAdmin$Administraresistem$Administraresistem" = 56,
	"TipRaport$Administraresistem$Administraresistem" = 59,
	"theme$Administraresistem$Administraresistem" = 60,
	"configBugetUserPeUnitate$Buget$Buget" = 61,
	"persoanaCurenta$Persoane$Persoane" = 62,
	"configSalariiUserPeUnitate$Salarii$Salarii" = 63,
	"configAdrese$Adrese$Adrese" = 64,
	"HomePagePin$Administraresistem$Administraresistem" = 65,
	"HomePageMenuType$Administraresistem$Administraresistem" = 66,
	"configContabUserPeUnitate$Contab$Contab" = 68,
	"configImpotax$Impotax$Impotax" = 69,
	"configIncasare$Incasarisiplati$Incasarisiplati" = 70,
	"configDocumente$Documente$Documente" = 71,
	"configAdministrare$Administrareinstitutie$Administrareinstitutie" = 74,
	"configRaportariFinanciare$Raportarifinanciare$Raportarifinanciare" = 89,
	"antetRaport$Administraresistem$Administraresistem" = 93,
	"configInvatamant$Invatamant$Invatamant" = 106,
	"antetLandscapeRaport$Administraresistem$Administraresistem" = 107,
	"configIncasareCasierie$Incasarisiplati$Incasarisiplati" = 108,
	"prevederiFaraRanduriZero$Buget$Buget" = 109,
	"ultimaGrupaSelectata$Invatamant$Invatamant" = 110,
	"invatamantProgramPrelungit$Invatamant$Invatamant" = 111,
	"configParametriiRaportBalanta$Contab$Contab" = 112,
	"listaAlteDocumenteSelection$Documente$Documente" = 113,
	"GlobalPayUrl$Incasarisiplati$Incasarisiplati" = 116,
	"GlobalPayKey$Incasarisiplati$Incasarisiplati" = 117,
	"configAsisoc$Asistentasociala$Asistentasociala" = 118,
	"configCantina$Cantina$Cantina" = 120,
	"numarExemplareListareChitante$Incasarisiplati$Incasarisiplati" = 121,
	"BorderouLaNivelDeOra$Incasarisiplati$Incasarisiplati" = 122,
	"Inchidereconturiautomata$Contab$Contab" = 123,
	"Incasaresimplificata$Incasarisiplati$Incasarisiplati" = 125,
	"invatamantInjumatatireTarifAlDoileaCopil$Invatamant$Invatamant" = 126,
	"prevederiDetalieriInfluenteTrimestriale$Buget$Buget" = 127,
	"invatamantRenumerotareDosareInceputDeAn$Invatamant$Invatamant" = 128,
	"configParametriiRaportCentralizatorBalanta$Contab$Contab" = 129,
	"raportIncasare$Incasarisiplati$Incasarisiplati" = 131,
	"trezorerieOperativa$Persoane$Persoane" = 132,
	"operareDocumenteLaData$Incasarisiplati$Incasarisiplati" = 133,
	"DataPlata$Incasarisiplati$Incasarisiplati" = 134,
	"Ascundesolduricu0$Incasarisiplati$Incasarisiplati" = 135,
	"predareBaniCasierieCentrala$Incasarisiplati$Incasarisiplati" = 136,
	"regularizareCodUnic$Incasarisiplati$Incasarisiplati" = 140,
	"configInvestitii$Investitii$Investitii" = 143,
	"inregistrareOperatiuniDirectInRegistruDeCasa$Incasarisiplati$Incasarisiplati" = 144,
	"Includetoateoperatiunile$Incasarisiplati$Incasarisiplati" = 147,
	"cantina$Stocurisiimobilizari$Stocurisiimobilizari" = 149,
	"GenerareAutomataSolicitare$Achizitii$Achizitii" = 150,
	"PersoanaTrimitereAutomataSolicitare$Achizitii$Achizitii" = 151,
	"Produsecudisponibil$Stocurisiimobilizari$Stocurisiimobilizari" = 152,
	"locFolosintaMf$Stocurisiimobilizari$Stocurisiimobilizari" = 153,
	"OmiteVizaCFPLaExecutieBugetara$Documente$Documente" = 158,
	"setareTipDocumentIncasariPrinBanca$Incasarisiplati$Incasarisiplati" = 159,
	"modGenerareObligatiiDePlata$Invatamant$Invatamant" = 160,
	"tipDocumentPredareNumerar$Incasarisiplati$Incasarisiplati" = 163,
	"PreluareSemnaturiUltimaPlanificare$Cantina$Cantina" = 167,
	"configImpotaxSetari$Impotax$Impotax" = 168,
	"dataInceputRefacturareUtilitati$Documente$Documente" = 169,
	"vizatPersoana$Achizitii$Achizitii" = 170,
	"tipVenitFiltru$Administrareinstitutie$Administrareinstitutie" = 171,
	"cuFiltruIesite$Stocurisiimobilizari$Stocurisiimobilizari" = 173,
	"Acundesolduricuzero$Incasarisiplati$" = 174,
	"adminUseriSelectati$Incasarisiplati$Incasarisiplati" = 176,
	"faraNoteContabileInvatamant$Invatamant$Invatamant" = 179,
	"dataOperareSimplificata$Incasarisiplati$Incasarisiplati" = 180,
	"configGhiseu$Incasarisiplati$Incasarisiplati" = 182,
	"isEfectuarePlatiDepasire$Buget$Buget" = 183
}