import { Injectable } from "@angular/core";
import { Entity, Validator, DataProperty, ValidationError } from 'breeze-client';
import dayjs from "dayjs";
import { isValidIBAN } from "ibantools"; // or alternative ibankit

@Injectable({ providedIn: 'root' })
export class ValidationService {
    constructor() {
        // mesaje default, poate fii dat alt mesaj din validator context fn
        Validator.messageTemplates.required = 'Câmpul %displayName% este obligatoriu!';
        Validator.messageTemplates.maxLength = 'Lungimea maximă a câmpului %displayName% este %maxLength%.';
    }

    isValidDate(data: Date): boolean {
        if (Object.prototype.toString.call(data) === "[object Date]") {
            return isNaN(data.getTime()) ? false : true // it is a date
        }
        // test string date
        return false
    }

    validateEmail(email: string): boolean {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    /**
     * valabil pt firme din RO
     * # Pas preliminar: Se testeaza daca codul respecta formatul unui cod CUI.Adica lungimea maxima sa fie de 10 cifre si sa contina doar caractere numerice.
     * # Pas 1: Se foloseste cheia de testare "753217532".Se inverseaza ordinea cifrelor codului CUI precum si a cheii de testare.
     * # Pas 2: Se ignora prima cifra din codul CUI inversat (aceasta este cifra de control) si se inmulteste fiecare cifra cu cifra corespunzatoare din cheia de testare inversata.
     * # Pas 3: Se aduna toate produsele obtinute.Suma rezultata se inmulteste cu 10 si apoi se afla restul impartirii la 11.
     * # Pas 4: Pentru un CUI valid cifra obtinuta, in urma operatiei MODULO 11, va trebui sa corespunda cu cifra de control a codului CUI initial .
     */
    isValidCui(cui: string | number): boolean {
        const sCui: string = cui.toString().trim().split('').reverse().join('');
        if (sCui.length > 10) { return false }

        // daca nu sunt toate numere nu ii valid 
        const iCui = sCui.split('').map(Number);
        // se adauga 0 pana la completarea lungimii 
        while (iCui.length != 10) { iCui.push(0) }

        const rezultat = (
            iCui[1] * 2 + iCui[2] * 3 + iCui[3] * 5 + iCui[4] * 7 + iCui[5] * 1 + iCui[6] * 2 + iCui[7] * 3 + iCui[8] * 5 + iCui[9] * 7
        ) * 10 % 11;

        return ((rezultat == 10 && iCui[0] == 0) || rezultat == iCui[0])
    }

    isValidCnp(cnp: string): boolean {
        /**cnp: S AA LL ZZ JJ NNN C*/
        var info = { sex: "", an: 0, luna: 0, zi: 0, judet: "", numar: 0, control: 0 };
        //Lungime
        if (!cnp.match(/^\d{13}$/)) return false;

        var sex = parseInt(cnp.substring(0, 1));
        //var an = parseInt(cnp.substring(1, 3));
        var judet = parseInt(cnp.substring(7, 9));
        info.luna = parseInt(cnp.substring(3, 5));
        info.zi = parseInt(cnp.substring(5, 7));
        info.numar = parseInt(cnp.substring(9, 12));
        info.control = parseInt(cnp.substring(12, 13));

        switch (sex) {
            case 1: case 3: case 5: info.sex = "masculin"; break;
            case 2: case 4: case 6: info.sex = "feminin"; break;
            case 7: info.sex = "masculin-rezident"; break;
            case 8: info.sex = "feminin-rezident"; break;
            case 9: info.sex = "strain"; break;
            default: return false;
        }
        if (info.luna > 12 || info.zi > 31) { return false }
        switch (sex) {
            case 1: case 2: info.an = parseInt("19" + cnp.substring(1, 3)); break;
            case 3: case 4: info.an = parseInt("18" + cnp.substring(1, 3)); break;
            case 5: case 6: info.an = parseInt("20" + cnp.substring(1, 3)); break;
            default: info.an = parseInt("19" + cnp.substring(1, 3)); break;
        }

        // var dataNasterii = dayjs(new Date(info.an, info.luna, info.zi));
        // return !(dataNasterii.isValid() && dataNasterii.isAfter(new Date(), 'day')) 

        info.judet = Judete[judet];

        //cifra control 
        var cheieTest = "279146358279", suma = 0, rest = 0;

        for (var i = 0; i < 12; i++) {
            suma += parseInt(cnp.charAt(i)) * parseInt(cheieTest.charAt(i));
        }
        rest = suma % 11;
        if (rest === 10) { rest = 1; }

        return rest === info.control;
    }

    public getCnpDetails(cnp: string):{ [key: string]: any } {
        /**cnp: S AA LL ZZ JJ NNN C*/
        var info = {
            sex: "",
            an: 0,
            luna: 0,
            zi: 0,
            judet: "",
            numarOrdine: 0,
            control: 0
        };
        
        //Lungime
        var length = cnp.match(/^\d{13}$/);
        if (!length)
            return undefined;

        var sex = parseInt(cnp.substring(0, 1));
        var an = parseInt(cnp.substring(1, 3));
        info.luna = parseInt(cnp.substring(3, 5)) - 1;
        info.zi = parseInt(cnp.substring(5, 7));
        var judet = parseInt(cnp.substring(7, 9));
        info.numarOrdine = parseInt(cnp.substring(9, 12));
        info.control = parseInt(cnp.substring(12, 13));

        if (sex === 1 || sex === 3 || sex === 5) {
            info.sex = "masculin";
        }
        else if (sex === 2 || sex === 4 || sex === 6) {
            info.sex = "feminin";
        }
        else if (sex === 7) {
            info.sex = "masculin-rezident";
        }
        else if (sex === 8) {
            info.sex = "feminin-rezident";
        }
        else if (sex === 9) {
            info.sex = "strain";
        }
        else
            return undefined;

        if (info.luna > 12 || info.zi > 31)
            return undefined;

        if (sex === 1 || sex === 2)
            info.an = parseInt("19" + cnp.substring(1, 3));
        else if (sex === 3 || sex === 4)
            info.an = parseInt("18" + cnp.substring(1, 3));
        else if (sex === 5 || sex === 6)
            info.an = parseInt("20" + cnp.substring(1, 3));
        else
            info.an = parseInt("19" + cnp.substring(1, 3));

        var dataNasterii = dayjs(new Date(info.an, info.luna, info.zi));
        var today = dayjs().format();
        if (dataNasterii.isValid()) {
            if (dataNasterii.isAfter(today))
                return undefined;
        }
        else
            return undefined;

        info.judet = Judete[judet];

        //cifra control 
        var cheieTest = "279146358279";
        var suma = 0;
        var rest = 0;

        for (var i = 0; i < 12; i++) {
            suma += parseInt(cnp.charAt(i)) * parseInt(cheieTest.charAt(i));
        }
        rest = suma % 11;
        if (rest === 10)
            rest = 1;

        if (rest != info.control)
            return undefined;

        return info;
    }

    isValidIban(iban: string): boolean { return isValidIBAN(iban) }

    isValidIbanRO(iban: string): boolean {
        var ord: number, ibanConv: string = "", subs: string = "";

        if (!iban) return false;
        iban = iban.replace(/ /g, "");
        if (iban.length != 24) { return false }

        for (var i = 0; i < iban.length; i++) {
            ord = iban.charCodeAt(i);
            if (!((ord >= 48 && ord <= 57) || (ord >= 65 && ord <= 90))) { return false }
        }

        var tmpIban = iban.substring(4) + iban.substring(0, 4);
        for (var i = 0; i < tmpIban.length; i++) {
            ord = tmpIban.charCodeAt(i);
            if (ord >= 65 && ord <= 90) {
                ibanConv += parseInt((ord - 55).toString());
            } else {
                ibanConv += tmpIban.substring(i, i + 1);
            }
        }

        var iArray = [], lenghtArray = [], i = 0;
        while (ibanConv.length > 0) {
            if (ibanConv.length > 9) {
                //lenghtArray[i] = 9;
                subs = ibanConv.substring(0, 9);
                while (subs.substring(0, 1) == '0') {
                    subs = subs.substring(1);
                }
                //iArray[i] = subs;
                ibanConv = ibanConv.substring(9);
            } else {
                //lenghtArray[i] = ibanConv.length;
                subs = ibanConv;
                while (subs.substring(0, 1) == '0') {
                    subs = subs.substring(1);
                }
                //iArray[i] = subs;
                ibanConv = "";
            }
            i++;
        }

        var rest = 0;
        for (var j = 0; j < i; j++) {
            rest = ((rest * (Math.pow(10, lenghtArray[j]))) + parseInt(iArray[j])) % 97;
        }
        return rest !== 1;
    }

    /**
     * Valideaza o entitate sau o proprietate
     */
    validate(entity: Entity, property?: string): ValidationError[] | void {
        if (entity.entityAspect) { return [] }
        if (property) { entity.entityAspect.validateProperty(property) }
        else { entity.entityAspect.validateEntity() }
        return entity.entityAspect.getValidationErrors()
    }

    addNonZeroValidator(property: DataProperty, msgTemplate?: string) {
        if (!property.isDataProperty || !property) return;
        property.validators.push(this.nonZeroValidator(msgTemplate))
    }
    nonZeroValidator(msgTemplate?: string) {
        return new Validator('nonZero', (value?: number) => (!value || value <= 0), {
            messageTemplate: msgTemplate || "Valoarea trebuie sa fie mai mare de 0",
            name: 'nonZero', displayName: 'nonZero',
        })
    }

    addCNPValidator(property: DataProperty, msgTemplate?: string) {
        if (!property.isDataProperty || !property) return;
        property.validators.push(this.cnpValidator(msgTemplate));
    }
    cnpValidator(msgTemplate?: string) {
        return new Validator('cnp', (value?: number | string): boolean => {
            if (!value) return false;
            if (typeof value === 'number') value = value.toString();
            return this.isValidCnp(value);
        }, {
            messageTemplate: msgTemplate || "CNP-ul introdus nu este valid!",
            name: 'cnp', displayName: 'cnp',
        })
    }

    addIbanValidator(property: DataProperty, msgTemplate?: string) {
        if (!property.isDataProperty || !property) return;
        property.validators.push(this.ibanValidator(msgTemplate))
    }
    ibanValidator(msgTemplate?: string) {
        return new Validator('iban', (value?: string): boolean => {
            if (!value) return true;
            return typeof value === 'string' && this.isValidIban(value)
        }, {
            messageTemplate: msgTemplate || "IBAN-ul introdus nu este valid!",
            name: 'iban', displayName: 'iban',
        })
    }
}

enum Judete {
    Alba = 1,
    Arad,
    Arges,
    Bacau,
    Bihor,
    BistritaNasaud,
    Botosani,
    Brasov,
    Braila,
    Buzau,
    CarasSeverin,
    Cluj,
    Constanta,
    Covasna,
    Dambovita,
    Dolj,
    Galati,
    Gorj,
    Harghita,
    Hunedoara,
    Ialomita,
    Iasi,
    Ilfov,
    Maramures,
    Mehedinti,
    Mures,
    Neamt,
    Olt,
    Prahova,
    SatuMare,
    Salaj,
    Sibiu,
    Suceava,
    Teleorman,
    Timis,
    Tulcea,
    Vaslui,
    Valcea,
    Vrancea,
    Bucuresti,
    BucurestiS1,
    BucurestiS2,
    BucurestiS3,
    BucurestiS4,
    BucurestiS5,
    BucurestiS6,
    Calarasi = 51,
    Giurgiu
}
